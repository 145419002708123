import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { style: {"margin-bottom":"8px","margin-top":"10px","text-align":"left","padding-left":"10px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_product_inventory = _resolveComponent("product-inventory")!
  const _component_product_inventory_specific_days = _resolveComponent("product-inventory-specific-days")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, {
    title: _ctx.$route.path.includes('create') ? 'Add Product' : 'Edit Product' 
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_spin, { spinning: _ctx.isLoading }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_a_form, {
              id: "myForm",
              labelCol: { span: 6 },
              wrapperCol: { span: 18 },
              model: _ctx.form,
              onFinish: _ctx.handleFinish
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, { label: "Product Name" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              type: "text",
                              id: "code",
                              required: "",
                              value: _ctx.form.product_name,
                              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.product_name) = $event))
                            }, null, 8, ["value"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, { label: "Product Category" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_select, {
                              value: _ctx.form.product_category_id,
                              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.product_category_id) = $event)),
                              id: "product_category_id",
                              "allow-clear": true,
                              "show-search": "",
                              filterOption: _ctx.filterOption
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.all_product_categories.data, (item) => {
                                  return (_openBlock(), _createBlock(_component_a_select_option, {
                                    key: item.value
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(item.label), 1)
                                    ]),
                                    _: 2
                                  }, 1024))
                                }), 128))
                              ]),
                              _: 1
                            }, 8, ["value", "filterOption"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, { label: "Product Type" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_select, {
                              value: _ctx.form.product_type_id,
                              "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.product_type_id) = $event)),
                              id: "product_type_id",
                              "allow-clear": true,
                              "show-search": "",
                              filterOption: _ctx.filterOption
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.all_product_types.data, (item) => {
                                  return (_openBlock(), _createBlock(_component_a_select_option, {
                                    key: item.value
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(item.label), 1)
                                    ]),
                                    _: 2
                                  }, 1024))
                                }), 128))
                              ]),
                              _: 1
                            }, 8, ["value", "filterOption"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_product_inventory),
                _createVNode(_component_product_inventory_specific_days),
                _createVNode(_component_a_row, { style: {"margin-top":"10px","float":"right"} }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_space, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_button, {
                          onClick: _ctx.handleBack,
                          size: "large"
                        }, {
                          default: _withCtx(() => _cache[3] || (_cache[3] = [
                            _createTextVNode(" Back ")
                          ])),
                          _: 1
                        }, 8, ["onClick"]),
                        _createVNode(_component_a_button, {
                          type: "primary",
                          "html-type": "submit",
                          size: "large"
                        }, {
                          default: _withCtx(() => _cache[4] || (_cache[4] = [
                            _createTextVNode(" Submit ")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["model", "onFinish"])
          ])
        ]),
        _: 1
      }, 8, ["spinning"])
    ]),
    _: 1
  }, 8, ["title"]))
}