import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_popconfirm = _resolveComponent("a-popconfirm")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_divider, { style: {"margin-bottom":"8px","margin-top":"50px"} }),
    _createElementVNode("h3", null, [
      _cache[0] || (_cache[0] = _createTextVNode(" Daily Product Inventory Variance Threshold ")),
      _createVNode(_component_a_button, {
        type: "primary",
        size: "small",
        onClick: _ctx.create
      }, {
        icon: _withCtx(() => [
          _createVNode(_component_plus_outlined)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createVNode(_component_a_table, {
      "row-key": "id",
      size: "small",
      columns: _ctx.columns,
      "data-source": _ctx.product.product_variance,
      bordered: "",
      style: {"overflow-x":"auto","overflow-y":"auto"}
    }, {
      label: _withCtx(({ record }) => [
        _createTextVNode(_toDisplayString((_ctx.all_variances.data.find(a => a.value == record.variance_id)).label), 1)
      ]),
      action: _withCtx(({ record }) => [
        (_ctx.isNew == false)
          ? (_openBlock(), _createBlock(_component_a_space, { key: 0 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  type: "primary",
                  onClick: ($event: any) => (_ctx.edit(record)),
                  size: "small"
                }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode(" Edit ")
                  ])),
                  _: 2
                }, 1032, ["onClick"]),
                _createVNode(_component_a_popconfirm, {
                  title: "Sure to delete?",
                  onConfirm: ($event: any) => (_ctx.deleteRecord(record))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, {
                      type: "danger",
                      size: "small"
                    }, {
                      default: _withCtx(() => _cache[2] || (_cache[2] = [
                        _createTextVNode(" Delete")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 2
                }, 1032, ["onConfirm"])
              ]),
              _: 2
            }, 1024))
          : (_openBlock(), _createBlock(_component_a_space, { key: 1 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  type: "primary",
                  onClick: ($event: any) => (_ctx.edit(record)),
                  size: "small"
                }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode(" Edit ")
                  ])),
                  _: 2
                }, 1032, ["onClick"]),
                _createVNode(_component_a_button, {
                  type: "danger",
                  size: "small",
                  onClick: ($event: any) => (_ctx.remove(record))
                }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode(" Remove")
                  ])),
                  _: 2
                }, 1032, ["onClick"])
              ]),
              _: 2
            }, 1024))
      ]),
      _: 1
    }, 8, ["columns", "data-source"]),
    _createVNode(_component_Form, {
      ref: "product_inventory_form",
      title: "Daily Product Inventory Variance Threshold"
    }, null, 512)
  ], 64))
}